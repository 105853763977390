import React from 'react';

function SocialLinks() {
    return (
        <div className="lead mb-4 text-mono text-success">
            <a href="https://discord.gg/a3zDHPG6be"><b>Join our Discord</b></a><br/>
            <a rel="me" href="https://infosec.exchange/@CackalackyCon"><b>Follow us on Mastodon</b></a><br/>
            <a href="https://bsky.app/profile/cackalackycon.bsky.social"><b>Follow us on Blue Sky</b></a><br/>
            <a href="https://twitter.com/cackalackycon"><b>Follow us on Twitter</b></a><br/>
            <a href="https://www.youtube.com/channel/@CackalackyCon"><b>Subscribe to our YouTube</b></a>
        </div>
    );
}

export default SocialLinks;
