import { useEffect, useState } from 'react';
import { auth, db } from './firebaseConfig';
import { collection, doc, getDoc, setDoc } from "firebase/firestore";

function useAuth() {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);
    const usersRef = collection(db, "users");

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                setUser(user);
                try {
                    const docRef = doc(db, "users", user.uid);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        setRole(docSnap.data().role);
                    } else {
                        await setDoc(doc(usersRef, user.uid), {role: "viewer"});
                    }
                } catch (error) {
                    console.error("Error getting document:", error);
                }
            } else {
                setUser(null);
                setRole(null);
            }
        });

        return () => unsubscribe();
    }, [db]);

    return { user, role };
}

export default useAuth;
