import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Admin from "../components/admin/Admin";
import HomePage from "../components/shared/HomePage";
import useAuth from "./auth";
import AdminNavbar from "../components/admin/NavBar";

function App() {
    const { user, role } = useAuth();
    return (
        <div className="App">
            <AdminNavbar />
            <Routes>
                <Route exact path="/" element={<HomePage />} />
                {
                    role === 'admin' ? <Route
                        path="/admin"
                        element={<Admin /> }
                    /> : <Route />
                }

            </Routes>
        </div>
    );
}

export default App;
