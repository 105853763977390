import React, { useState, useEffect } from 'react';
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../../admin/firebaseConfig';

function Admin() {
  const [headerText, setHeaderText] = useState('');
  const [newHeaderText, setNewHeaderText] = useState('');
  const [dateText, setDateText] = useState('');
  const [newDateText, setNewDateText] = useState('');

  useEffect(() => {
    const fetchHeaderText = async () => {
      const segments = ['content']
      const docRef = doc(db, "home", ...segments);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setHeaderText(docSnap.data().header.text);
        setDateText(docSnap.data().dates.text);
      } else {
        console.log("No such document!");
        // const defaultText = 'CackalackyCon';
        // await setDoc(docRef, { text: defaultText });
        // setHeaderText(defaultText);
      }
    };

    fetchHeaderText();
  }, [db]);

  const updateHeaderText = async () => {
    const segments = ['content']
    const docRef = doc(db, "home", ...segments);
    await updateDoc(docRef, { header: { text: newHeaderText } });
    await updateContentJSON();
    setHeaderText(newHeaderText);
    setNewHeaderText('');
  };

  const updateDatesText = async () => {
    const segments = ['content']
    const docRef = doc(db, "home", ...segments);
    await updateDoc(docRef, { dates: { text: newDateText }});
    await updateContentJSON();
    setDateText(newDateText);
    setNewDateText('');
  };

  const updateContentJSON = async () => {
    let url = `https://${process.env.ADMIN_API_ENDPOINT}`
    if(process.env.ADMIN_API_ENDPOINT === "localhost") {
      url = `http://${process.env.ADMIN_API_ENDPOINT}:${process.env.ADMIN_API_PORT}`
    }
    fetch(`${url}/api/update-data`, {method: "POST"})
        .then(response => response.text())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
  };

  const publish = async () => {
    let url = `https://${process.env.ADMIN_API_ENDPOINT}`
    if(process.env.ADMIN_API_ENDPOINT === "localhost") {
      url = `http://${process.env.ADMIN_API_ENDPOINT}:${process.env.ADMIN_API_PORT}`
    }
    fetch(`${url}/api/publish`, {method: "POST"})
        .then(response => response.text())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));
  };

  return (
      <div>
        <h1>Admin Panel</h1>
        <div>
          <h2>Home page</h2>
          <h3>Current Header Text:</h3>
          <p>{headerText}</p>
          <input
              type="text"
              value={newHeaderText}
              onChange={(e) => setNewHeaderText(e.target.value)}
          />
          <button onClick={updateHeaderText}>Update Header Text</button>
          <button onClick={publish}>Publish</button>
        </div>
        <div>
          <h3>Current Dates:</h3>
          <p>{dateText}</p>
          <input
              type="text"
              value={newDateText}
              onChange={(e) => setNewDateText(e.target.value)}
          />
          <button onClick={updateDatesText}>Update Dates Text</button>
        </div>
      </div>
  );
}

export default Admin;
