import Header from "./Header";
import SocialLinks from "./SocialLinks";
import React from "react";

function HomePage() {
    return (
        <div className="bg-transparent mb-0 radius-0">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6">
                        <Header />
                        <SocialLinks />
                        <br />
                        <br />
                        <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/Caib2nXG_BY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        <br />
                        <br />
                        <a target="_blank" href="https://www.youtube.com/watch?v=q8O-cL9caOQ">
                            <img src="https://cackalacky.nyc3.cdn.digitaloceanspaces.com/images/landing/mess.png" alt="Now With Less Limes" className="center" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;