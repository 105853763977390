import React, { useState, useEffect } from 'react';

function Header() {
    const [headerText, setHeaderText] = useState('');
    const [datesText, setDatesText] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch the JSON content from the CDN
        fetch('/proxy/content/home')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setHeaderText(data.header.text);
                setDatesText(data.dates.text);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching content:', error);
                setError(error);
                setLoading(false);
            });
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading content</div>;
    }

    return (
        <div>
            <img
                src="https://cackalacky.nyc3.cdn.digitaloceanspaces.com/images/landing/CackalackyCon_SecondaryLogoFull_RGB.png"
                alt="Now With More Limes"
                className="center"
                width="350"
                height="125"
            />
            <br /><br /><br />
            <div className="lead mb-4 text-mono text-success">
                <span style={{ fontSize: 'x-large' }}>{headerText}</span><br /><br />
                {datesText} <br />
                DoubleTree by Hilton Hotel Raleigh-Durham Airport at Research Triangle Park
                <span> <a href="https://maps.app.goo.gl/twobP2NxtJi2s1TC7">Google Map</a></span>
                <br />
                <br />Call for Papers will open "soon"
            </div>
        </div>
    );
}

export default Header;
