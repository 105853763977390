import React from 'react';
import useAuth from "../../admin/auth";
import {signInWithGooglePopup, signOutUser} from "../../admin/firebaseConfig";
import Navbar from "../shared/Navbar";

function AdminNavbar() {
    const {user, role} = useAuth();

    const handleSignIn = async () => {
        try {
            await signInWithGooglePopup();
        } catch (error) {
            console.error('Error signing in', error);
        }
    };

    const handleSignOut = async () => {
        try {
            await signOutUser();
        } catch (error) {
            console.error('Error signing out', error);
        }
    };

    return (
        <Navbar
            user={user}
            role={role}
            handleSignIn={handleSignIn}
            handleSignOut={handleSignOut}
            enableAdmin={true}
        />
    );
}

export default AdminNavbar;
